<template>
  <div class="row justify-content-center"  style="margin-top: -8%;">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Mot de passe oublié ?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Entrez votre adresse e-mail ci-dessous et nous vous enverrons le lien de réinitialisation
          </p>
          <div v-if="errors!==''" style="margin-top: 5%;">
            <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
            <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>              
            
          </div>
          <div v-if="success!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
            </div>
          <form  ref="form" @submit.prevent="forgot_password">
            <div class="form-group mb-15 mb-sm-20">
                <label class="d-block text-black fw-semibold mb-10">
                Email 
                </label>
              <input
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="ex: adam127704@gmail.com"
                v-model="formValues.email"
                @blur="validate('email')"
                @keypress="validate('email')"
                :class="{ 'is-invalid': errorsBis['email'] }"
              />
              <span class="invalid-feedback">{{ errorsBis['email']}}</span>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
              style="background-color: #EB9355;" :disabled="!btnLoad"
            >
            <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Envoyer
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
              >
              <!-- login -->
                <i class="ph-bold ph-arrow-left"></i>
                Revenir pour se connecter
              </router-link>
              <!-- <router-link
                to="/reset-password/45388b35-b7c1-4ba3-815e-82bb4a322aae"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
              >             
                <i class="ph-bold ph-arrow-left"></i>
                reset form
              </router-link> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// export default {
//   name: "ForgotPassword",
// };
import router from '@/router';
import axios from 'axios';
import { ref, reactive,onMounted,inject } from 'vue';
import * as yup from 'yup';

const backApi=inject('back');
const errors = ref('');
const success = ref('');
const errorId = ref('');
const errorsBis = {'email':'','password':''};
const btnLoad = ref(true);

const schema = yup.object({
     email: yup.string().email('l\'e-mail doit être un e-mail valide').required('L\'email est requis'),    
});

const formValues = reactive({
  email: "", 
});

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;
          
        });
        errors.value=''
}

const forgot_password = async () => {
    try {  
        schema.validateSync(formValues);       
        btnLoad.value=false;
        axios.post(backApi+'/api/v1/public/users/forgot-password', {
          email: formValues.email,          
        }, {
        headers: {          
          'Content-Type': 'application/json',           
        }
      }).then((response) => {
        btnLoad.value=true; 
        if(response.status === 200){
          success.value=response.data.msg
                    
        }
      }).catch((error) => {
        btnLoad.value=true; 
          if( error.response){  
              errorId.value=error.response.data.errorId;            
              errors.value=error.response.data.message;              
          }
      });
    } catch (error) {
          console.error(error);        
          errors.value="une erreur inattendue est apparue"
        //alert(error  )
    }
    
}
</script>