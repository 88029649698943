<template>
  <BreadCrumb PageTitle="Liste des frais" />
  <FeeContent />
</template>

<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import FeeContent from "../../components/Settings/FeeContent.vue";


export default defineComponent({
  name: "FeePage",
  components: {
    BreadCrumb,
    FeeContent,
  },
  setup(){
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    onMounted(() => {
          stateStoreInstance.show = true;
        });
       return {stateStoreInstance};
  }
});
</script>