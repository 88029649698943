<template>
  <div v-if="errors!==''" style="margin-top: 5%;">
        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
      </div>
      <div v-if="success!==''" style="margin-top: 5%;">
        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
  </div>
  <div class="clearfix"  v-if="!loader">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="loader" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">       
        <select  name="country" id="country" class="form-select fs-md-15 text-black shadow-none" @change="handleCountryChange" required>
          <option value="">Selectionnez un pays </option>                 
          <option  value="SN" >Sénégal</option>
          <option  value="CI" > Côte d'Ivoire</option>
       </select>
       &nbsp; &nbsp; &nbsp;
       <!-- <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorChange" required>
          <option value="">Selectionnez un opérateur</option>                 
          <option v-for="operator in operators" :value="operator.mnoName" :key="operator.code"> {{ operator.mnoName }}</option>
      </select> -->
      </div>
      <div class="d-sm-flex align-items-center">
          <input id="fileUpload" type="file" @change="handleImageUpload">
          
        
           <button
                class="default-outline-btn bg-transparent position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
                type="button"
                @click="getModelExcel"          
                  >       
                Télécharger le fichier modèle 
                <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
          </button>
          
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table id="" class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                NOM
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                PRENOM(S)
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TELEPHONE
              </th>
               <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                MONTANT
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                OPERATEUR
              </th>               
            </tr>
          </thead>
          <tbody>

            <tr  v-for="(transfert) in transferts" :key="transfert.mobile">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                    />
                  </div>
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                   
                    {{ transfert.name}}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.lastname}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.mobile}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.amount.toLocaleString()}}
              </td>
               <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.operator}}
              </td> 
              <!-- <td class="shadow-none lh-1 fw-medium">
                {{ transfert.country}}                
              </td> -->
             
            </tr>
           
          </tbody>
        </table> 
        
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
        <p  class="mb-0 text-paragraph">
          <!-- Showing <span class="fw-bold">{{ page*10 }}</span> out of-->
          Total: <span class="fw-bold">{{ total }}</span>  
        </p>
        <nav    class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li    class="page-item">
              <!-- <a class="page-link"  aria-label="Previous"> -->
              Montant Total :<span class="fw-bold">{{ totalTransfertAmount.toLocaleString() }}</span> XOF
              <!-- </a> -->
            </li>
           
           
           
            <li   class="page-item">
              <button
                  type="button"
                  class="d-block text-center w-100 border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25"
                   
                  style="background-color: #EB9355;" 
                  @click="sendMoney" 
                >
                   Transferer l'argent
              </button>
            </li>
          </ul>
        </nav>

       

        
      </div>
    </div>
  </div>
 
</template>

<script setup  lang="ts">
import {ref, onMounted,onBeforeMount,reactive,inject} from "vue";
import * as yup from 'yup';
import axios from 'axios';
import flatpickr from "flatpickr";
import html2pdf from "html2pdf.js";
import LeadscontentPdf from "./LeadsContentPdf.vue";
import router from '../../../router/index'
import conf from '../../../../env.json'
import {current_permissions} from '../../../utils/permission';
import { read, utils } from 'xlsx';
const backApi=inject('back');

declare interface Transaction {
    id: number,
    checkoutId: string,   
    operator: string,
    contact: string,
    amount: string,    
    transactionDate: string,
    paymentStatus: string
  }

  declare interface Organization {
  id : string,
  code: string,
  name: string,
  description: string,    
  email: string,
  created_at: string
  }

  declare interface Operator {
  // id : string,
  // code: string,
  mnoName: string,
  status: string,    
  countryCode: string  
  }

const leadPlaceholder = "Select date";
const errors = ref('');  
const errorId = ref('');  
const success = ref('');
const errorsBis = {'contact':'','amount':''};
const formValues = reactive({    
    contact: "",
    amount:""
  });
const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({     
     contact: yup.string().required('Le numéro de téléphone est requis'),//.matches(phoneRegExp,"Veuillez saisir un numéro de telephone valide").test('len', "Le numero de téléphone doit faire exactement 10 chiffres", val => val.length === 10),     
     amount: yup.number().required('Le montant est requis'),
  });
let data = [];
let beginDate = ref('');
let endDate = ref('');
let today = ref('');
const selectedOrganization = ref('');
const selectedOperator = ref('');
const selectedStatus = ref('');
const selectedCountry = ref('');
const loader = ref(false);
const page = ref(1);
const total = ref(0);
//const pageServ = ref('');
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const gettran = ref(false);
const search = ref(false);
const search1 = ref(false);
const amount = ref(0);
const operator = ref('');
const paymentStatus = ref(''); 
let importArray = [];
const transferts = ref([]);
let totalAmount = 0;
const totalTransfertAmount = ref(0);
let transactionsJson = [];
// const fileUpload = ref('');

    
const transactions =ref(new Array<Transaction>());
const organizations =ref(new Array<Organization>());
const operators =ref(new Array<Operator>());
let operatorsTemp =new Array<Operator>();
const transaction =ref({} as Transaction);

  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

function formatDateEn(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function formatDateEnBis(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function handleOrganizationChange(event) {
      selectedOrganization.value = event.target.value;
    }

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
  errors.value='';
  success.value='';
  errorId.value='';
}

function handleStatusChange(event) {
  selectedStatus.value = event.target.value;
}

function handleCountryChange(event) {
   selectedCountry.value = event.target.value; 
  transferts.value = [];
  importArray=[];
  transactionsJson=[];
  totalTransfertAmount.value = 0;
  totalAmount = 0;
  // let fileUpload = 
  document.getElementById("fileUpload").value="";
  //  fileUpload.val
  total.value=0;
   errors.value='';
   success.value='';
   errorId.value='';
}

function handleCountryChangeOLD(event) {
   selectedCountry.value = event.target.value;
  // operators
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
   operators.value = operatorArr;
   errors.value='';
   success.value='';
   errorId.value='';
}

function handleAmountChange(event) {    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
}

function getModelExcel() {       
        window.open(`/model_payout.xls`, "_blank");
}
  
onMounted(() => {
 
  // getOrganizations();
  getOperators();
  loader.value=true; 
  setTimeout(function () {
    //loader.value=true; 
        flatpickr("#lead-calendar", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: "today",
        enableTime: true,
      });
        },1000);

 

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });

});
const token =  localStorage.getItem('login_token');
const code =  localStorage.getItem('organization_code');


const getOrganizations = () =>{

    axios.get(backApi+'/api/v1/secure/organizations/',{  
      headers: {
           
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {
        // users.value = response.data;
        organizations.value = response.data;  //.data.users          
        console.log(response.data);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
                //router.push('/');
              }   
              
          }
   });
  //  loader.value=true;
}
const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  //secure
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});
//  loader.value=true;
}

//[top, left, bottom, right]


const sendMoney =()=>{
if(selectedCountry.value!==''){// && selectedOperator.value!==''
try {  
  // alert(selectedOperator.value);
  axios.post(`${backApi}/api/v1/secure/transactions/init`,{    
    organization:code,    
    payoutType:2, 
    operationType:parseInt(process.env.VUE_APP_PAYOUT_OPTTYPE),    
    //countryCode:selectedCountry.value,
    //operator: selectedOperator.value,
    transactions: transactionsJson
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => { 
        selectedCountry.value ='';
        selectedOperator.value ='';
        if(response.status === 200 || response.status === 201){      
          router.go();
          success.value='Transfert réussi'; 
         
          //console.log(response);
        }      
        
    }).catch((error) => {
      if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}
}else{
  errors.value="Sélectionnez le pays s'il vous plaît"
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value='';
        success.value='';
}

const handleImageUpload = (event) => {
  loader.value=false;
  const file = event.target.files[0];
      const reader = new FileReader();
      transferts.value = [];
      importArray=[];
      transactionsJson=[];
      totalTransfertAmount.value = 0;
      totalAmount = 0;
      total.value=0;
      errors.value='';
      success.value='';
      errorId.value='';
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      
        var i = 0;
        for (const row of jsonData) {
          //(row[0]!==undefined && row[0]!=='') && (row[1]!==undefined && row[1]!=='') && (row[2]!==undefined && row[2]!=='') && (row[3]!==undefined && row[3]!=='') && (row[4]!==undefined && row[4]!=='')
          if((row[0]!==undefined && row[0]!=='NOM') && (row[1]!==undefined && row[1]!=='PRENOM') && (row[2]!==undefined && row[2]!=='PHONE_NUMBER') && (row[3]!==undefined && row[3]!=='AMOUNT')  && (row[4]!==undefined && row[4]!=='PAYMENT_METHOD')){// && (row[4]!==undefined && row[4]!=='PAYMENT_METHOD')
              if((row[2].length==10||row[2].length==8) && parseInt(row[3])>0){
                const user = {
                  name: row[0], 
                  lastname: row[1], 
                  mobile: row[2],
                  amount: row[3],
                  operator: row[4],
                  // country: row[5],           
                };
                const temp ={
                  amount: row[3],
                  mobile: row[2],
                  operator: row[4],
                  countryCode:selectedCountry.value
                }
                importArray.push(user);
                transactionsJson.push(temp);
                totalAmount+= parseInt(row[3]);//(i>0)? :''
                i++;
               }else{              
               //if((row[2].length!==10 && row[2].length!==8) && parseInt(row[3])>0){
                  errors.value='Données incorrectes dans le fichier';              
                  errorId.value='Ligne '+(i+1);
               }
             
           
          }
          //else{
            //  if((row[0]==undefined || row[0]=='') || (row[1]==undefined || row[1]=='')  ||  (row[2]==undefined  ||  row[2]=='')  ||  (row[3]==undefined  ||  row[3]=='')  ||  (row[4]==undefined  ||  row[4]=='')){
            //   errors.value='Données incorrectes dans le fichier';              
            //   errorId.value='Ligne '+(i-1);
            //  }
          //}
          
        }
        // i = 0;
        total.value=i;//i-1;
        // importArray.shift();
        // transactionsJson.shift();
        transferts.value = importArray;        
        totalTransfertAmount.value = totalAmount;
        
      };
      loader.value=true;
      // setTimeout(function () {
      //   loader.value=true;
      // }, 1000) 
      reader.readAsArrayBuffer(file);
          
}

</script>
<style scoped>
#title{
 text-decoration: underline;
 margin-bottom: 2%;
}
#transactionsTable{
 display: none; 
 /* visibility: hidden;*/
}
</style>