<template>
  <BreadCrumb PageTitle="Liste des pays" />
  <CountryContent />
</template>

<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import CountryContent from "../../components/Settings/CountryContent.vue";


export default defineComponent({
  name: "CountryPage",
  components: {
    BreadCrumb,
    CountryContent,
  },
  setup(){
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    onMounted(() => {
          stateStoreInstance.show = true;
        });
       return {stateStoreInstance};
  }
});
</script>