<template>
  <div
    class="card ml-1 border-0 rounded-0 bg-white text-center error-404-card"
    style="border-color: red;border-style: solid;margin-top: -5%; height: 10%;"
  >
    <div class="card-body letter-spacing">
      <img src="../assets/images/error-404.png" alt="error-image" height="30%" style="height:30%"/>
      <h1 class="fw-bold text-black">OOPS! 404 Page Not Found!</h1>
      <router-link
        class="default-btn transition text-decoration-none border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
        to="/"
      >
        Retour à l'ecran de connexion
      </router-link>
    </div>
  </div>
</template>

<script>
import { onMounted,defineComponent } from "vue";
import stateStore from "../utils/store";
export default {
  name: "ErrorPage",
  setup(){
        const stateStoreInstance = stateStore;
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");        
        onMounted(() => {
          stateStoreInstance.show = false;
        });
       return {stateStoreInstance};
  }
};
</script>