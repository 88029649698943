<template>
  <BreadCrumb PageTitle="Register" />
  <RegisterContent />
</template>

<script lang="ts">
import { onMounted,defineComponent } from "vue";
import stateStore from "../../utils/store";

//import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import RegisterContent from "../../components/Authentication/Register/RegisterContent.vue";

export default defineComponent({
  name: "RegisterPage",
  components: {
    //BreadCrumb,
    RegisterContent,
  },
  setup(){
        const stateStoreInstance = stateStore;
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");        
        onMounted(() => {
          stateStoreInstance.show = false;
        });
       return {stateStoreInstance};
  }
});
</script>