import router from '@/router';

export const current_permissions = (perm) => {
    const permissions = localStorage.getItem('role'); 
       
        return permissions==perm;   
  };
  export const can_access = (perm) => {       
        if(current_permissions(perm)){
          return true;
          }else{
            //window.location.href = '/dashboard';
            router.push('/dashboard')
        }    
  };

  export const current_permissions_old = (perm) => {
    const permissionsStr = localStorage.getItem('permissions'); 
        const permissionsTemp = JSON.parse(permissionsStr) ;
        const permissions = permissionsTemp.permissions;  
        //console.log(permissionsTemp.permissions);
        return permissions.includes(perm.trim());   
  };