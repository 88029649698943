<template>
  <BreadCrumbDetail PageTitle="Les utilisateurs de " />
  <UsersContentByCompany />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumbDetail from "../../components/Common/BreadCrumbDetail.vue";
import UsersContentByCompany from "../../components/CRM/Leads/UsersContentByCompany.vue";


export default defineComponent({
  name: "UsersByCompanyPage",
  components: {
    BreadCrumbDetail,
    UsersContentByCompany,
  },
});
</script>