export function formatNumber(str) {
    //console.log('str',str)
    
    var valueFormatted = '';
    str? str.toString().includes(',')
    ?valueFormatted = str.toString().replace(/,/g, ' ').replace(/\./g, ',')
    :valueFormatted = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    :valueFormatted="0";
    
    return valueFormatted;
}

export function formatDateFr(value){
  if(value){
      const year = value.getFullYear();
      let month =value.getMonth() + 1;
      month = month<10?'0'+month:month;
      let day = value.getDate();  
      day = day<10?'0'+day:day;      
      return day+'/'+month+'/'+year;
      
  }
}

export const validate_phone_number = (myvalue,tag,len=10) => { 
 
    var phone = myvalue;  
    phone = phone.replace(/\s+/g, '');
      var temp = phone.substring(phone.length-1,phone.length);
      if(phone.search("[^0-9]")>=0){ 
        if(temp!='' && temp<=9 && phone.length<=len){
            // 
        }else{
          if(typeof(temp)==='string'){      
            tag.value = phone.substring(0,phone.length-1);             
          }
          if(phone.substring(0,phone.length-1).search("[^0-9]")>=0){
            tag.value='';                     
          }
          
                    
        }         
      }
      if(phone.length>len){        
        tag.value = phone.substring(0,phone.length-1);  
      }
  }
