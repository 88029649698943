<template>
  <!-- <BreadCrumb PageTitle="Logout" /> -->
  <LogoutContent />
</template>

<script lang="ts">
import { onMounted,defineComponent } from "vue";

import BreadCrumb from "../components/Common/BreadCrumb.vue";
import LogoutContent from "../components/Logout/LogoutContent.vue";
import stateStore from "../utils/store";

export default defineComponent({
  name: "LogoutPage",
  components: {
    // BreadCrumb,
    LogoutContent,
  },setup() {   
        const stateStoreInstance = stateStore;
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");//header
       
        onMounted(() => {
          stateStoreInstance.show = false;
        });
       return {stateStoreInstance};
  },
});
</script>