<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
       
        
      </div>
      <div class="d-flex align-items-center">
        <button
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#companyModal"
          style="background-color: #EB9355;"
        >
          Ajouter un frais
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>


      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
              Code 
            </th> 
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Organisation
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Pays
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Opérateur
              </th>             
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Nom
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Valeur
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Mode
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Type frais
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr   v-for="(fee) in fees" :key="fee.id">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.code }}
              </td> 
              <td  v-if="fee.organization!==null" class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.organization.name }}
              </td>
              <td  v-if="fee.organization===null" class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ '' }}
              </td>
              <td  v-if="fee.country!==null"  class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.country.name }}
              </td> 
              <td  v-if="fee.country===null" class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ '' }}
              </td> 
              <td  v-if="fee.operator!==null" class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.operator.mnoName }}
              </td>
              <td  v-if="fee.operator===null" class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ '' }}
              </td>                  
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.value }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.mode }}
              </td> 
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ fee.typeFee.name }}
              </td>            
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" v-if="fee.status==='ACTIVE'">Actif</span>
                <span class="badge text-outline-danger" v-if="fee.status==='INACTIVE'">Inactif</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                   
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="changeStatus(fee.status,fee.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="fee.status==='ACTIVE'">Désactiver</span>
                        <span v-if="fee.status==='INACTIVE'">Activer</span>
                      </a>
                    </li>                    
                  </ul>
                </div>
              </td>
            </tr>
                      
          </tbody>
        </table>
      </div>
      <div
      v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
      >
        <p v-if="total>10" class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ page*10 }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <p v-if="total<=10"  class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ total }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0" class="page-item">
              <a class="page-link" @click="getFees(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="getFees(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="getFees(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

   
  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="storeFee">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Frais</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">                            
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Nom
                                </label>              
                                <div >                                     
                                        <input
                                          v-model="formValues.name"
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          id="lead-calendar"
                                          placeholder="taux de ..."
                                          @blur="validate('name')"
                                          @keyup="validate('name')"
                                          :class="{ 'is-invalid': errorsBis['name'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['name']}}</span> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Valeur
                                </label>              
                                <div >                                     
                                        <input
                                          v-model="formValues.valeur"
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          id="lead-calendar"
                                          placeholder="2"
                                          @blur="validate('valeur')"
                                          @keyup="validate('valeur')"
                                          :class="{ 'is-invalid': errorsBis['valeur'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['valeur']}}</span> 
                                </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Mode
                                </label>              
                                <select  name="mode" id="mode" class="form-select fs-md-15 text-black shadow-none" @change="handleModeChange" required>
                                    <option value="">Selectionnez...</option>                 
                                    <option  value="PERCENT" >Pourcentage</option>
                                    <option  value="VALUE" > Valeur</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Type de frais 
                                </label>              
                                <select  name="typefee" id="typefee" class="form-select fs-md-15 text-black shadow-none" @change="handleTypeFeeChange" required>
                                      <option value="">Selectionnez...</option>                 
                                      <option v-for="typefee in typefees" :value="typefee.code" :key="typefee.code"> {{ typefee.name }}</option>
                                  </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Organisation
                                </label>              
                                <select  name="organization" id="organization" class="form-select fs-md-15 text-black shadow-none" @change="handleOrganizationChange" required>
                                    <option value="">Selectionnez...</option>                 
                                    <option v-for="organization in organizations" :value="organization.code" :key="organization.code"> {{ organization.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Pays
                                </label>              
                                <select  name="country" id="country" class="form-select fs-md-15 text-black shadow-none" @change="handleCountryChange" required>
                                      <option value="">Selectionnez...</option>                 
                                      <option v-for="country in countries" :value="country.countryCode" :key="country.code"> {{ country.name }}</option>
                                  </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                    Opérateurs
                                  </label>
                                  <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorChange" required>
                                      <option value="">Selectionnez...</option>                 
                                      <option v-for="operator in operators" :value="operator.code" :key="operator.code"> {{ operator.mnoName }}</option>
                                  </select>
                            </div>
                            
                        </div>

                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  Enregister
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../router/index'
import { useRouter } from 'vue-router';
import * as yup from 'yup';
import { Country,Fee,Operator,Organization,TypeFree } from "@/utils/Model";
import { getTypeFee } from "../../utils/services";

const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);

const selectedOrganization = ref('');
const selectedOperator = ref('');
const selectedCountry = ref('');
const selectedTypeFee = ref('');
const selectedMode = ref('');

const loader = ref(false);

let token: string|null = localStorage.getItem('login_token');


const countries =ref(new Array<Country>());
const fees =ref(new Array<Fee>());
const organizations =ref(new Array<Organization>());
const operators =ref(new Array<Operator>());
// const typefees =ref([]);
const typefees =ref(new Array<TypeFree>());
let operatorsTemp =new Array<Operator>();
let countriesTemp =new Array<Country>();
 
  const errors = ref('');  
  const errorId = ref('');  
  const success = ref('');
  const errorsBis = {'name':'','valeur':''};
  const pageLoad = ref(false);

  const formValues = reactive({    
    valeur: "",  
    name: ""   
  });

  
  const schema = yup.object({    
      valeur: yup.number().required('La valeur est requise'),  
      name: yup.string().required('Le nom du frais est requis'),   
     
  });

  function handleOrganizationChange(event) {
      selectedOrganization.value = event.target.value;
    }

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
}

function handleTypeFeeChange(event) {
  selectedTypeFee.value = event.target.value;
}

function handleModeChange(event) {
  selectedMode.value = event.target.value;
}

function handleCountryChange(event) {
  // // selectedStatus.value = event.target.value;
  var i = 0;
  while(i<countriesTemp.length){    
      if(countriesTemp[i].countryCode == event.target.value){         
          selectedCountry.value=countriesTemp[i].code;
      }      
      i++;
  }
  // operators
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
   operators.value = operatorArr;
}

function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

const getFees = (arg=1) =>{
    // +arg
    axios.get(backApi+'/api/v1/secure/fees/',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        fees.value = response.data;  //.data.fees            
        
        // total.value=parseInt(response.data.pagination.total);
        // page.value=parseInt(response.data.pagination.page);
        // pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        // currentPage.value=parseInt(response.data.pagination.currentPage);
        // numPages.value=parseInt(response.data.pagination.numPages);

        total.value=10;
        page.value=1;
        pagesLeft.value=0;
        currentPage.value=1;
        numPages.value=1;
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const getOrganizations = () =>{

axios.get(backApi+'/api/v1/secure/organizations/',{  
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    organizations.value = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            //window.location.href = '/';
            //router.push('/');
          }   
          
      }
});
//  loader.value=true;
}

const getTypeFees = () =>{

axios.get(backApi+'/api/v1/secure/typefees/',{  
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    typefees.value = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            //window.location.href = '/';
            //router.push('/');
          }   
          
      }
});
//  loader.value=true;
}

const getCountries = () =>{

axios.get(backApi+'/api/v1/secure/countries/',{  
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
     countriesTemp = response.data;
    countries.value = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            //window.location.href = '/';
            //router.push('/');
          }   
          
      }
});
//  loader.value=true;
}

const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  
headers: {
   
    Authorization: `Bearer ${token}`
} 
})
.then((response) => {
operatorsTemp = response.data;  //.data.users          
console.log(response.data);

}).catch((error) => {
  if( error.response){
      console.log(error.response.data); // => the response payload 
      if(error.response.status === 401){
        router.push('/')        
      }   
      
  }
});

}

const changeStatus =(status,code)=>{
 // alert(code)
  axios.put(`${backApi}/api/v1/secure/fees/${code}/status`,{
          code: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const storeFee =()=>{
try {  
  schema.validateSync(formValues);
  // alert(formValues)
  axios.post(`${backApi}/api/v1/secure/fees/new`,{          
          value:parseFloat(formValues.valeur),
          name:formValues.name,
          organization:selectedOrganization.value,
          country:selectedCountry.value,
          operator:selectedOperator.value,
          mode:selectedMode.value,
          typeFee:selectedTypeFee.value
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

// name:name.value,
// email:email.value,
// contact:contact.value,
// description:description.value
}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}


 onMounted(async () => { 
  //alert(token)
   getFees();
   getOperators();
   getOrganizations();
   getTypeFees();
   getCountries();
  // console.log(typefees.value);
    //typefees.value= await getTypeFee(token);
    // console.log(typefees.value);
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
});
</script>