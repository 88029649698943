<template>
  <div class="row justify-content-center"  style="margin-top: -8%;">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            Créez votre compte
          </h4>
          <div v-if="errors!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
            </div>
            <div v-if="success!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
            </div>
          <form  @submit.prevent="register">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Raison sociale</label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. I-MONEY AFRICA"
                v-model="formValues.company_name"
                @blur="validate('company_name')"
                @keypress="validate('company_name')"
                :class="{ 'is-invalid': errorsBis['company_name'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['company_name']}}</span>
            </div>
            <!-- <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Votre nom complet</label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Jacob Adam"
                v-model="formValues.name"
                @blur="validate('name')"
                @keypress="validate('name')"
                :class="{ 'is-invalid': errorsBis['name'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['name']}}</span>
            </div> -->
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email 
              </label>
              <input
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. adam127704@gmail.com"
                v-model="formValues.email"
                @blur="validate('email')"
                @keyup="validate('email')"
                :class="{ 'is-invalid': errorsBis['email'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['email']}}</span>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Contact 
              </label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. 0500005555"
                v-model="formValues.contact"
                @blur="validate('contact')"
                @change="validate('contact')"
                :class="{ 'is-invalid': errorsBis['contact'] }"
              />  
                <!-- @keyup="validate('contact')" -->                 
              <span class="invalid-feedback">{{ errorsBis['contact']}}</span>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Mot de passe
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                v-model="formValues.password"
                @blur="validate('password')"
                @keyup="validate('password')"
                :class="{ 'is-invalid': errorsBis['password'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['password']}}</span>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Confirmation du mot de passe
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                v-model="formValues.confirm_password"
                @blur="validate('confirm_password')"
                @keyup="validate('confirm_password')"
                :class="{ 'is-invalid': errorsBis['confirm_password'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['confirm_password']}}</span>
            </div>
            <div
              class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1 mb-15 mb-md-20"
            >
              <!-- <input
                class="form-check-input shadow-none"
                type="checkbox"
                id="remember-me"
              />
              <label class="form-check-label" for="remember-me">
                J'accepte tout
                <a href="#" class="text-decoration-none">Termes & Conditions</a>
              </label> -->
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
              style="background-color: #EB9355;"
            >
            S'inscrire maintenant
            </button>
            <span
              class="d-block text-muted text-center mt-15 mt-md-30 mb-12 mb-md-20 fs-md-15 fs-lg-16"
            >
            Vous avez déjà un compte?
            </span>
            <router-link
              to="/"
              class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center"
            >
            Connecte-toi ici 
            </router-link>
            <!-- <span
              class="d-block or text-muted text-center mt-15 mb-15 mt-md-20 mb-md-20 fs-md-15 fs-lg-16 position-relative z-1 lh-1"
            >
              <span class="d-inline-block bg-white">Or</span>
            </span> -->
            <!-- <ul class="socials ps-0 mb-0 list-unstyled text-center">
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative facebook border-0 p-0"
                >
                  <i class="ph-fill ph-facebook-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative google border-0 p-0"
                >
                  <i class="ph-bold ph-google-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative twitter border-0 p-0"
                >
                  <i class="ph-fill ph-twitter-logo"></i>
                </button>
              </li>
              <li class="d-inline-block">
                <button
                  type="button"
                  class="d-block rounded-circle text-center position-relative linkedin border-0 p-0"
                >
                  <i class="ph-fill ph-linkedin-logo"></i>
                </button>
              </li>
            </ul> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// export default {
//   name: "RegisterContent",
// };
import router from '@/router';
import axios from 'axios';
import { ref, reactive,inject } from 'vue';
import * as yup from 'yup';
import data from '../../../../env.json'

const backApi=inject('back');
const error = ref('');
const errors = ref('');
const errorId = ref('');
const success = ref('');
const errorsBis = {'email':'','password':'','confirm_password':'','company_name':'','contact':''};//'name':'',
// const VUE_APP_BASE_URL_PREPROD = ref(process.env.VUE_APP_BASE_URL_PREPROD)

// const formValues = ref();

const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({
    password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères'),
    confirm_password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères').oneOf([yup.ref('password'), null], "La confirmation du mot de passe doit être identique au mot de passe"),
    email: yup.string().email('l\'e-mail doit être un e-mail valide').required('L\'email est requis'),
   company_name: yup.string().required('La raison sociale est requise'),
    //name: yup.string().required('Votre nom est requis'),
    contact: yup.string().required('Le numéro de téléphone est requis').matches(phoneRegExp,"Veuillez saisir un numéro de telephone valide").test('len', "Le numero de téléphone doit faire exactement 10 chiffres", val => val.length === 10),     
});

const formValues = reactive({
  email: "",
  password: "",
  confirm_password: "",  
  company_name: "",
  //name: "",
  contact: ""
});

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;
          // alert(errorsBis[field])
        });
        errors.value=''
    }


const register = async () => {
  
      try {  
        
       
        schema.validateSync(formValues);
        
        axios.post(backApi+'/api/v1/insecure/organizations/new', {//http://127.0.0.1:8000
          email: formValues.email,
          password: formValues.password,
          name: formValues.company_name,
          contact: formValues.contact,
          //name: formValues.name,
          description: formValues.company_name
        }, {
        headers: {          
          'Content-Type': 'application/json',           
        }
      }).then((response) => {
        if(response.status === 200){
          success.value="Inscription effectuée avec succès nous vous contacterons pour la validation de votre compte"
                    
        }
      }).catch((error) => {
          if( error.response){  
              errorId.value=error.response.data.errorId;            
              errors.value=error.response.data.message;              
          }
      });
        

      } catch (error) {
        console.error(error);
        
         errors.value="une erreur inattendue est apparue"//ValidationError:
      
      }
 
};

</script>