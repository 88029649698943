<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="row justify-content-center" style="margin-top: -8%;">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            Connectez-vous à votre compte!
          </h4>
          <!-- v-slot="{ errors }" -->
            <div v-if="errors!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>              
              
            </div>
          <form ref="form" @submit.prevent="login" >
            
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Adresse email  
                  </label>
                  <input
                    type="email"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. adam127704@gmail.com"
                    v-model="formValues.email"
                    @blur="validate('email')"
                    @keypress="validate('email')"
                    :class="{ 'is-invalid': errorsBis['email'] }"
                  />
                   <!-- :class="{ 'is-invalid': errors.email }" -->
                  <span class="invalid-feedback">{{ errorsBis['email']}}</span>

            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Mot de passe
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                v-model="formValues.password"  
                @blur="validate('password')"
                @keypress="validate('password')"
                :class="{ 'is-invalid': errorsBis['password'] }"             
              />
              <span class="invalid-feedback">{{ errorsBis['password']}}</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-15 mb-md-20"
            >
              <div
                class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1"
              >
<!--               
                <input
                  class="form-check-input shadow-none"
                  type="checkbox"
                  id="remember-me"
                />
                <label class="form-check-label" for="remember-me">
                  Se souvenir de moi
                </label> -->
              </div>
              <router-link
                to="/forgot-password"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
              >
                Mot de passe oublié ?
              </router-link>
            </div>
            <!-- bg-success -->
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
              style="background-color: #EB9355;" :disabled="!btnLoad"
            >
            <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Connectez vous maintenant
            </button>
            <span
              class="d-block text-muted text-center mt-15 mt-md-30 mb-12 mb-md-20 fs-md-15 fs-lg-16"
            >
            Vous n'avez pas de compte ?
            </span>
            <router-link
              to="/register"
              class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center"
              
            >
            Créer un compte
            </router-link>
           
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// export default {
//   name: "LoginForm",
// };
import router from '@/router';
import axios from 'axios';
import { ref, reactive,onMounted,inject } from 'vue';
import * as yup from 'yup';
import data from '../../../../env.json'
import {formatNumber} from "../../../utils/function";

//import router from '../../../router/index'
const email = ref('');
const password = ref('');
const error = ref('');
const errors = ref('');
const errorId = ref('');
const errorsBis = {'email':'','password':''};
let result = true;
let dataJson = [];
const pageLoad = ref(false);
const btnLoad = ref(true);
const transactions = ref(true);
const backApi=inject('back');
// const formValues = ref();


const schema = yup.object({
    password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 5 caractères'),
    email: yup.string().email('l\'e-mail doit être un e-mail valide').required('L\'email est requis'),    
});

const formValues = reactive({
  email: "",
  password: "",
});

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;
          // alert(errorsBis[field])
        });
        errors.value=''
}

function validateForm() {

  
  for(var data in errorsBis) {   
  
      schema
        .validateAt(data, formValues)
        .then(() => {
          errorsBis[data] = "";
        })
        .catch(err => {
          errorsBis[data] = err.message;
          result = false;
          //console.log(result);
        });
      
  }
  return result;

}

function formatDateEn(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function getPeriod(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;          
        return year+'-'+month;
        
    }
}


//alert(inject('back'))

const login = async () => {
  //alert(process.env.VUE_APP_API_BASE)
   //if(validateForm()){
      //alert(errorsBis['password'])    
      try {  
        
       
        schema.validateSync(formValues);
        //pageLoad.value=false;
        btnLoad.value=false;
        // alert(inject('back'))
      axios.post(backApi+'/api/v1/login', {//http://127.0.0.1:8000
          email: formValues.email,
          password: formValues.password
        }, {
        headers: {          
          'Content-Type': 'application/json',           
        }
      }).then((response) => {
        if(response.status === 200){
          console.log(response.data);
          localStorage.setItem('login_token', response.data.token);
          localStorage.setItem('organization_code', response.data.organization.code);
          localStorage.setItem('organization_name', response.data.organization.name);
          localStorage.setItem('code', response.data.code);
          localStorage.setItem('name', response.data.name);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('status', response.data.status);
          localStorage.setItem('role', response.data.role);
          let token = response.data.token;
          //pageLoad.value=true;
          ////btnLoad.value=true;

         // window.location.href = '/dashboard';
         //router.push('/dashboard')

              const day =  new Date(); 
              const todayE = getPeriod(day);   
              //conf.proxy // data.proxy
              //process.env.VUE_APP_API_BASE
              axios.get(`${backApi}/api/v1/secure/organizations/statistic?dateD=${todayE}&dateF=${todayE}&organization=${response.data.organization.code}`,{//SERVER+ //https://test.zivwallet.com/api/countries//dateD=${today}&
                  headers: {
                      Authorization: `Bearer ${response.data.token}`
                }
              })
              .then((response) => {
                     
                  dataJson  = response.data;  
                  console.log(dataJson);
                  localStorage.setItem('organizationDonut', JSON.stringify({donut:dataJson.organizationDonut})); 
                  localStorage.setItem('organizationChartbyMonth', JSON.stringify({chart:dataJson.monthyChart})); 
                  localStorage.setItem('weekChart', JSON.stringify({chart:dataJson.weekChart})); 
                  localStorage.setItem('day', dataJson.day);
                  localStorage.setItem('weekBalance', dataJson.weekBalance);
                  

                  //btnLoad.value=true; 
                  // router.push('/transactions'); 
                  //router.push('/dashboard');

                    //console.log(dataJson);
                  
              }).catch((error) => {
              // loader.value=true;
                    if( error.response){
                        console.log(error.response.data); // => the response payload 
                        if(error.response.status === 401){                          
                          router.push('/');
                        }   
                        
                    }
            });

            //get balance
            // getBalanceAvailable()
            axios.post(backApi+`/api/v1/secure/accounts/balance/available`,{//collect//collectBalance
                    organization: response.data.organization.code,         
                    },{  
                    headers: {
                        
                          Authorization: `Bearer ${token}`
                    } 
            })
            .then((response) => {    
                        const balanceTemp =  formatNumber(parseInt(response.data.balance));
                        localStorage.setItem('availableBalance', balanceTemp);
                        // localStorage.setItem('availableBalance', response.data.availableBalance);
                    }).catch((error) => {
                      console.log(error.response.data); 
            });
            // getCollectBalance()
            axios.post(backApi+`/api/v1/secure/accounts/balance/collect`,{
                  organization: response.data.organization.code,         
                },{  
                headers: {
                    
                      Authorization: `Bearer ${token}`
                } 
                })
              .then((response) => {    
                    const balanceTemp1 =  formatNumber(parseInt(response.data.balance));
                    localStorage.setItem('collectBalance', balanceTemp1);
                    // localStorage.setItem('collectBalance', response.data.collectBalance);
              }).catch((error) => {
                  console.log(error.response.data);
              });

              axios.post(backApi+`/api/v1/secure/accounts/balance/payout`,{
                  organization: response.data.organization.code,         
                },{  
                headers: {
                    
                      Authorization: `Bearer ${token}`
                } 
                })
              .then((response) => {    
                    const balanceTemp2 =  formatNumber(parseInt(response.data.balance));
                    localStorage.setItem('balance', balanceTemp2);
              }).catch((error) => {
                  console.log(error.response.data);
              });
            
              btnLoad.value=true; 
              router.push('/dashboard');
        }
      }).catch((error) => {
           //pageLoad.value=true;
           btnLoad.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              //errors.value=error.response.data
             
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
               
          }
      });
      

      } catch (error) {
        console.error(error);
        // error.value = 'une erreur inattendue est apparue';
       // errors.value=error.replace('ValidationError:', '')
         errors.value="une erreur inattendue est apparue"//ValidationError:
      //alert(error  )
      }
   //}
};

// const getBalanceAvailable = () =>{

//   axios.post(process.env.VUE_APP_API_BASE+`/api/v1/secure/accounts/balance/available`,{//collect//collectBalance
//             code: response.data.organization.code,         
//           },{  
//           headers: {
              
//                 Authorization: `Bearer ${token}`
//           } 
//   })
//   .then((response) => {    
//         //.data.users  
//       localStorage.setItem('availableBalance', response.data.availableBalance);
//   }).catch((error) => {
//     console.log(error.response.data); 
//   });
//   //  loader.value=true;
//   }

  // const getCollectBalance = () =>{

  //   axios.post(process.env.VUE_APP_API_BASE+`/api/v1/secure/accounts/balance/collect`,{
  //             code: response.data.organization.code,         
  //           },{  
  //           headers: {
                
  //                 Authorization: `Bearer ${token}`
  //           } 
  //   })
  //   .then((response) => {    
        
  //       localStorage.setItem('collectBalance', response.data.collectBalance);
  //   }).catch((error) => {
  //     console.log(error.response.data);
  //   });
  //   //  loader.value=true;
  //   }
  const reset = async () => {
        try {  
            
          localStorage.removeItem('login_token');
          localStorage.removeItem('organization_code');  
          localStorage.removeItem('organization_name');
          localStorage.removeItem('name');
          localStorage.removeItem('email');
          localStorage.removeItem('status');
          localStorage.removeItem('role');  
          localStorage.removeItem('organizationDonut'); 
          localStorage.removeItem('organizationChartbyMonth'); 
          localStorage.removeItem('weekChart'); 
          localStorage.removeItem('day');
          localStorage.removeItem('weekBalance');    
          localStorage.removeItem('dailyBalance')          
          
        } catch (error) {
          console.error(error);
        
        }
    };
onMounted(() =>{ 
  reset() 
  setTimeout(function () {
    pageLoad.value=true; 
        },1000)
})


</script>