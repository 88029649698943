<template>
  <div class="row">
    <div class="col-xxl-7 col-xxxl-6">
      <WhatHappening />

      <div class="row">
        <div class="col-lg-8 col-xxxl-8 col-md-8">
          <!-- <ExpectedEarnings /> -->
        </div>
        <!-- <div class="col-lg-6 col-xxxl-6 col-md-6">
          <AverageDailySales />
        </div>
        <div class="col-xl-12 col-lg-6 col-xxxl-6 col-md-12">
          <RevenueThisMonth />
        </div>
        <div class="col-xl-12 col-lg-6 col-xxxl-6 col-md-12">
          <NewCustomersThisMonth />
        </div> -->
        <div class="col-xl-12 col-lg-12 col-xxxl-12 col-md-12">
            <WebsiteVisitors />
            
         </div>
      </div>
    </div>
    <div class="col-xxl-5 col-xxxl-6">
      <StatsBoxes />

      <WeeklySales />
    </div>
    <div class="col-xxl-5 col-xxxl-6">
      <!-- <WebsiteVisitors /> -->
      <!-- <SalesByPOSLocation /> -->
    </div>
    <div class="col-xxl-4">
      <TopSellingProducts />
    </div>
    <div class="col-xxl-4">
      <!-- <WebsiteVisitors /> -->
    </div>
    <div class="col-xxl-6">
      <ProductsOrders />
    </div>
    <div class="col-xxl-6">
      <StockReport />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";
import { useRouter } from "vue-router";


import WhatHappening from "../../components/Dashboard/Ecommerce/WhatHappening.vue";

import ExpectedEarnings from "../../components/Dashboard/Ecommerce/ExpectedEarnings.vue";

import AverageDailySales from "../../components/Dashboard/Ecommerce/AverageDailySales.vue";

import RevenueThisMonth from "../../components/Dashboard/Ecommerce/RevenueThisMonth.vue";

import NewCustomersThisMonth from "../../components/Dashboard/Ecommerce/NewCustomersThisMonth.vue";

import StatsBoxes from "../../components/Dashboard/Ecommerce/StatsBoxes/index.vue";

import WeeklySales from "../../components/Dashboard/Ecommerce/WeeklySales.vue";

import SalesByPOSLocation from "../../components/Dashboard/Ecommerce/SalesByPOSLocation.vue";

import TopSellingProducts from "../../components/Dashboard/Ecommerce/TopSellingProducts/index.vue";

import WebsiteVisitors from "../../components/Dashboard/Ecommerce/WebsiteVisitors.vue";

import ProductsOrders from "../../components/Dashboard/Ecommerce/ProductsOrders/index.vue";

import StockReport from "../../components/Dashboard/Ecommerce/StockReport/index.vue";

// import MainHeader from "../../components/Layouts/MainHeader.vue";
// import MainSidebar from "../../components/Layouts/MainSidebar.vue";
// import MainFooter from "../../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "EcommercePage",
  components: {
    
    // StatsBoxes, display statut count
    WeeklySales,
    //ExpectedEarnings,//manage error
    WebsiteVisitors,
     //AverageDailySales,
    
    //RevenueThisMonth,

    /// WhatHappening,
    /// NewCustomersThisMonth,      
     ///SalesByPOSLocation,

    /// TopSellingProducts,
    
    // ProductsOrders,
    /// StockReport,
    
    // MainHeader,
    // MainSidebar,
    // MainFooter,
  },
  setup(){
    
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    const router = useRouter();
    //alert(router.options.history.state.back)
    onMounted(() => {
          stateStoreInstance.show = true;
          
        });
       return {stateStoreInstance};
  }
});
</script>