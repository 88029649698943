<template>
  <!-- <BreadCrumb PageTitle="Register" /> -->
  <TicketContent />
</template>

<script lang="ts">
import { onMounted,defineComponent } from "vue";
import stateStore from "../../utils/store";

//import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import TicketContent from "../../components/Public/Ticket/TicketContent.vue";

export default defineComponent({
  name: "TicketPage",
  components: {
    //BreadCrumb,
    TicketContent,
  },
  setup(){
        const stateStoreInstance = stateStore;
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");        
        onMounted(() => {
          stateStoreInstance.show = false;
        });
       return {stateStoreInstance};
  }
});
</script>