<template>
  <div class="row justify-content-center" style="margin-top: -8%;">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Réinitialiser le mot de passe ?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Entrez votre nouveau mot de passe et confirmez-le une autre fois dans le champ ci-dessous
          </p>
             <div v-if="errors!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
            </div>
            <div v-if="success!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
            </div>
          <form  ref="form" @submit.prevent="reset_password">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Mot de passe 
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                v-model="formValues.password"
                @blur="validate('password')"
                @keyup="validate('password')"
                :class="{ 'is-invalid': errorsBis['password'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['password']}}</span>
            </div>
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10">
                Confirmation du mot de passe
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                v-model="formValues.confirm_password"
                @blur="validate('confirm_password')"
                @keyup="validate('confirm_password')"
                :class="{ 'is-invalid': errorsBis['confirm_password'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['confirm_password']}}</span>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
              style="background-color: #EB9355;" :disabled="!btnLoad"
            >
            <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Réinitialiser le mot de passe
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
                
              >
                <i class="ph-bold ph-arrow-left"></i>
                Revenir pour se connecter
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// export default {
//   name: "ResetPassword",
// };
import router from '@/router';
import axios from 'axios';
import { ref,onMounted, reactive,inject } from 'vue';
import { useRoute } from 'vue-router'
import * as yup from 'yup';

const backApi=inject('back');
const errors = ref('');
const errorId = ref('');
const success = ref('');
const btnLoad = ref(true);
let code='';
const errorsBis = {'password':'','confirm_password':''};

const schema = yup.object({
    password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères'),
    confirm_password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères').oneOf([yup.ref('password'), null], "La confirmation du mot de passe doit être identique au mot de passe"),
    
});

const formValues = reactive({
    password: "",
  confirm_password: "",  
 
});

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;
          // alert(errorsBis[field])
        });
        errors.value=''
}

const reset_password = async () => {
  
  try {  
    
   
    btnLoad.value=false;
    schema.validateSync(formValues);
    
    axios.post(backApi+'/api/v1/public/users/reset-password', {
      code: code,
      newPassword: formValues.password,
      checkPassword: formValues.confirm_password      
    }, {
    headers: {          
      'Content-Type': 'application/json',           
    }
  }).then((response) => {
    btnLoad.value=true; 
    if(response.status === 200){
      //success.value=response.data.msg
      success.value=response.data
      
      const myRedirect = setTimeout(redirect, 3000);
      
                
    }

  }).catch((error) => {
    btnLoad.value=true; 
      if( error.response){  
          errorId.value=error.response.data.errorId;            
          errors.value=error.response.data.message;              
      }
  });
    

  } catch (error) {
    console.error(error);
    
     errors.value="une erreur inattendue est apparue"//ValidationError:
  
  }
  

};
function redirect() {
        router.push('/')
}

onMounted(() => {  
const route = useRoute();
code = route.params.code;

});
</script>