import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumbDetail = _resolveComponent("BreadCrumbDetail")!
  const _component_UsersContentByCompany = _resolveComponent("UsersContentByCompany")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumbDetail, { PageTitle: "Les utilisateurs de " }),
    _createVNode(_component_UsersContentByCompany)
  ], 64))
}