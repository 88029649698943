<template>
    <div>
      <iframe title="PDF" width="100%" height="1000px" :src="`/pdfjs-4.0.379-dist/web/viewer.html?file=${documentUrl}`"></iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: "UserManuelPage",
    data() {
      return {
        documentUrl: "/izypay.pdf"
      }
    }
  }
  </script>