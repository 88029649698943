<template>
  <BreadCrumb PageTitle="Liste des réversements" />
  <RepaymentContent />
</template>

<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import RepaymentContent from "../../components/Financial/RepaymentContent.vue";


export default defineComponent({
  name: "RepaymentPage",
  components: {
    BreadCrumb,
    RepaymentContent,
  },
  setup(){
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    onMounted(() => {
          stateStoreInstance.show = true;
        });
       return {stateStoreInstance};
  }
});
</script>