<template>
  <!-- <BreadCrumb PageTitle="Reset Password" /> -->
  <ResetPassword />
</template>

<script lang="ts">
import {  onMounted,defineComponent } from "vue";
import stateStore from "../../utils/store";

// import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ResetPassword from "../../components/Authentication/ResetPassword/ResetPassword.vue";

export default defineComponent({
  name: "ResetPasswordPage",
  components: {
    // BreadCrumb,
    ResetPassword,
  },
  setup(){
        const stateStoreInstance = stateStore;
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");        
        onMounted(() => {
          stateStoreInstance.show = false;
        });
       return {stateStoreInstance};
  }
});
</script>