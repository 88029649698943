<template>
  <BreadCrumb PageTitle="Envois d'argent" />
  <TransfertsContent />
</template>

<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import TransfertsContent from "../../components/CRM/Leads/TransfertsContent.vue";


export default defineComponent({
  name: "TransfertsPage",
  components: {
    BreadCrumb,
    TransfertsContent,
  },
  setup(){
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    onMounted(() => {
          stateStoreInstance.show = true;
        });
       return {stateStoreInstance};
  }
});
</script>