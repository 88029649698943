<template>
  <!-- <BreadCrumb PageTitle="Forgot Password" /> -->
  <ForgotPassword />
</template>

<script lang="ts">
import {  onMounted,defineComponent } from "vue";
import stateStore from "../../utils/store";

// import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ForgotPassword from "../../components/Authentication/ForgotPassword/ForgotPassword.vue";

export default defineComponent({
  name: "ForgotPasswordPage",
  components: {
  //  BreadCrumb, 
    ForgotPassword,
  },
  setup(){
        const stateStoreInstance = stateStore;
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");        
        onMounted(() => {
          stateStoreInstance.show = false;
        });
       return {stateStoreInstance};
  }
});
</script>