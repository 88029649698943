<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <button
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#companyModal"
          style="background-color: #EB9355;"
        >
          Créer un utilisateur
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Export
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-sm-flex align-items-center">
        <!-- <form class="search-box position-relative">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Search lead"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form> -->
        <div
          class="select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0"
        >
          <!-- <i class="flaticon-calendar lh-1 icon"></i>
          <input
            ref="leadCalendar"
            type="text"
            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
            id="lead-calendar"
            :placeholder="leadPlaceholder"
          /> -->
        </div>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#filterLeadModal"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
               ID
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                NOM
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                NOM UTILISATEUR
              </th>
               <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                E-MAIL
              </th> 
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                STATUT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>

            <tr  v-for="(user) in users" :key="user.code">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                    />
                  </div>
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                   
                    {{ user.code}}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ user.name}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ user.username}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ user.email}}
              </td>
             
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" v-if="user.status==='ACTIVE'">ACTIVE</span>
                <span class="badge text-outline-danger" v-if="user.status==='INACTIVE'">INACTIVE</span>                
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                 <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <!-- <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        View</a
                      >
                    </li> -->
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="changeStatus(user.status,user.code)"

                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="user.status==='ACTIVE'">Désactiver</span>
                        <span v-if="user.status==='INACTIVE'">Activer</span>
                        </a
                      >
                    </li>
                    
                  </ul>
                </div>
              </td>
            </tr>
           
          </tbody>
        </table>
      </div>
     
    </div>
  </div>

  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="storeUser">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Creation d'un utilisateur</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                          
                          <div class="row">
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Nom complet
                                  </label>
                                  <div >
                                          
                                          <input
                                            v-model="formValues.name"
                                            type="text"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                            
                                            placeholder="ex: Ouattara Patrick"
                                            @blur="validate('name')"
                                            @keyup="validate('name')"
                                            :class="{ 'is-invalid': errorsBis['name'] }"
                                          />
                                          <span class="invalid-feedback">{{ errorsBis['name']}}</span> 
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    E-mail
                                  </label>
                                  <div >
                                                                      
                                        <input
                                        v-model="formValues.email"
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                          
                                          placeholder="ex: info@imoney.africa"
                                          @blur="validate('email')"
                                          @keyup="validate('email')"
                                          :class="{ 'is-invalid': errorsBis['email'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['email']}}</span> 
                                  </div>
                              </div>
                        </div>
                        <div class="row">
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Mot de passe 
                                  </label>
                                  <div >
                                          
                                          <input
                                            v-model="formValues.password"
                                            type="password"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                            
                                            placeholder="***********"
                                            @blur="validate('password')"
                                            @keyup="validate('password')"
                                            :class="{ 'is-invalid': errorsBis['password'] }"
                                          />
                                          <span class="invalid-feedback">{{ errorsBis['password']}}</span> 
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Confirmation du mot de passe 
                                  </label>
                                  <div >
                                                                      
                                        <input
                                        v-model="formValues.confirm_password"
                                          type="password"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                     
                                          placeholder="***********"
                                          @blur="validate('confirm_password')"
                                          @keyup="validate('confirm_password')"
                                          :class="{ 'is-invalid': errorsBis['confirm_password'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['confirm_password']}}</span> 
                                  </div>
                              </div>
                        </div>
                        
                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  Enregister
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import flatpickr from "flatpickr";
import { useRoute, useRouter } from 'vue-router'
import conf from '../../../../env.json'
import router from '../../../router/index'
import * as yup from 'yup';
const backApi=inject('back');

const errors = ref('');  
const success = ref('');
const errorsBis = {'name':'','email':'','password':'','confirm_password':''};
const pageLoad = ref(false);

  const formValues = reactive({
    name: "", 
    // role: "",
    //status: "", 
    email: "",          
    password: "",
    confirm_password: "",    
    //organization: ""
  });

  
  const schema = yup.object({    
      email: yup.string().email('l\'e-mail doit être un e-mail valide').required('L\'email est requis'),
      name: yup.string().required('Le nom complet est requis'),   
      password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères'),
      confirm_password: yup.string().required('La confirmation du mot de passe est requis').min(6, 'La confirmation du mot de passe doit faire au moin 6 caractères').oneOf([yup.ref('password'), null], "La confirmation du mot de passe doit être identique au mot de passe")
  });



declare interface User {
    id: number ,
    code: string,
    name: string,
    status: string,    
    email: string,
    username: string
  }
const leadPlaceholder = "Select Lead Date";
const users =ref(new Array<User>());
  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
let code:string|null='';
onMounted(() => {
  flatpickr("#lead-calendar", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    defaultDate: "today",
  });

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });
  const route = useRoute()
  code = route.params.id;
  getUsers(code);

  setTimeout(function () {
    pageLoad.value=true; 
         },1000)
  
});
const token =  localStorage.getItem('login_token');
const getUsers = (code) =>{

  axios.get(`${backApi}/api/v1/secure/users/?organization=${code}`,{
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        users.value = response.data;  //.data.users          
        // console.log(users.value);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
              }   
              
          }
  });
}

const storeUser =()=>{
try {  
  schema.validateSync(formValues);
  axios.post(`${backApi}/api/v1/secure/users/new`,{
          name:formValues.name,
          role:'USER',
          status:'ACTIVE',
          email:formValues.email,
          password:formValues.password,
          organization:code
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}


}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}

const changeStatus =(status,code)=>{
  axios.post(`${backApi}/api/v1/secure/users/activate`,{
          code: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}
</script>