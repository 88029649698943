<template>
  <!-- <BreadCrumb PageTitle="Login" /> -->
  <LoginForm />
</template>

<script lang="ts">
import {ref, onMounted,defineComponent } from "vue";

// import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import LoginForm from "../../components/Authentication/Login/LoginForm.vue";
import stateStore from "../../utils/store";

export default defineComponent({
  name: "LoginPage",
  components: {
    // BreadCrumb,
    LoginForm,
  },
  setup() {   
        const stateStoreInstance = stateStore;
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");//header
        // document.body.classList.add("invisible");
        // document.body.classList.remove("bg-body-secondary");
        console.log("show");      
        onMounted(() => {
          stateStoreInstance.show = false;
        });
       return {stateStoreInstance};
  },
});
</script>
