<template>
  <BreadCrumbDetail PageTitle="Paiements collectés par " />
  <LeadsContentByCompany />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumbDetail from "../../components/Common/BreadCrumbDetail.vue";
import LeadsContentByCompany from "../../components/CRM/Leads/LeadsContentByCompany.vue";


export default defineComponent({
  name: "LeadsByCompanyPage",
  components: {
    BreadCrumbDetail,
    LeadsContentByCompany,
  },
});
</script>